<script>
    const $moment = require('moment');
    
    import MapBoxGL from 'mapbox-gl';
    import Map from '@/utils/map';
    import { watch } from '@/utils/dom';
    import VehicleService  from '@/services/VehicleService';
    import MapLayersService from '@/services/MapLayersService';
    import MapSettingsService from '@/components/dev/service/MapSettingsService';
    import ShowRouteMapService from '@/components/services/ShowRouteMapService';
    
    import { isEmpty } from "@/utils/utils";
    
    const OM_STATE = {
        "none": -1,
        "default": 0,
        "loading": 1,
        "error": 999
    };
    
    const get_the_time = function(t, only) {
        const fmt = (!!only) ? 'HH:mm' : 'DD.MM.YYYY HH:mm';
        return (!!t) ? $moment(t).format(fmt) : '-';
    };  //get_the_time

    
    var map   = null,
        popup = null;
    
    export default {
        name: 'OnMap',
        props: {
            violInfo: {
                type: Object,
                required: false,
                default: null
            }
        },
        data(){
            return {
                show: false,
                state: OM_STATE.none,
                vi: null,
                track: null
            };
        },
        mounted(){
            var node = $(".vi-map-dialog #vi-map")[0];
            Map.init({container: node}, true).then((_o)=>{
                map = _o.map;
            });
        },
        methods: {
            mapVi(){
                const p = (resolve, reject) => {
                    const _watch = ()=>{
                        if ((!!map)&&map.loaded()){
                            resolve();
                        } else {
                            setTimeout(_watch, 200);
                        }
                    };
                    _watch();
                };
                return new Promise(p);
            },  //mapVi
            async getViolInfo(){
                this.state = OM_STATE.loading;
                try {
                    var resp = await $http.post('/rpc?d=jsonRpc', {
                        type: 'core-read',
                        query: 'sin2:/v:b718738d-d427-4a96-bac4-e23233fb346c/?id=' + this.violInfo.id,
                        dateWork: new Date(this.violInfo.dt)
                    });
                    if (!!resp.error){
                        throw resp.error;
                    }
                    if (resp.result.data.length < 1){
                        throw {message: 'No data found'};
                    }
                    console.log('control', resp.result);
                    const ci = resp.result.columnIndexes,
                         data= resp.result.data[0];
                    const vi = {
                        id: data[ci["vctripscontrol.id"]],
                        dt: $moment(data[ci["vctripscontrol.eventdt"]]).toDate(),
                        name: data[ci["vctripscontrol.typename"]],
                        she: data[ci["temptripscontroljoin.plantime"]],
                        lat: data[ci["vctripscontrol.lat"]],
                        lon: data[ci["vctripscontrol.lon"]],
                        vc: {
                            id: data[ci["vctripscontrol.vehicleid"]],
                            gov: data[ci["temptripscontroljoin.govnum"]]
                        },
                        trip: {
                            id: data[ci["vctripscontrol.tripid"]],
                            code: data[ci["temptripscontroljoin.tripcode"]],
                            has: !$utils.isEmpty(data[ci["vctripscontrol.tripid"]])
                        },
                        route: {
                            id: data[ci["temptripscontroljoin.routeid"]],
                            code: data[ci["temptripscontroljoin.routecode"]],
                            name: data[ci["temptripscontroljoin.routename"]],
                            has: !$utils.isEmpty(data[ci["temptripscontroljoin.routeid"]])
                        },
                        org: {
                            code: data[ci["temptripscontroljoin.orgcode"]],
                            name: data[ci["temptripscontroljoin.shortname"]],
                            has:  !$utils.isEmpty(data[ci["temptripscontroljoin.shortname"]])
                        }
                    };
                    vi.dt1 = $moment(vi.dt).add(-5, 'm').toDate();
                    vi.dt2 = $moment(vi.dt).add(5, 'm').toDate();
                    console.log('VI', vi);
                    this.vi = vi;
                    this.state = OM_STATE.default;
                    this.mapVi().then(()=>{
                        map.resize();
                        this.popup();
                        VehicleService.getHistoryTrack({id: vi.vc.id}, vi.dt1, vi.dt2).then((track)=>{
                            console.log('track at', track);
                            if ((track.length > 0)&&(track[0].points.length > 0)){
                                this.track = track[0];
                                const markers = VehicleService.getTrackMarkers(track, track[0].points);
                                Map.drawTrack({
                                    track: this.track, 
                                    markers: markers,
                                    vehicle: {id: vi.vc.id},
                                    settings: MapSettingsService.getSettings({id: vi.vc.id})
                                });
                            } else {
                                this.track = null;
                                jet.msg({
                                  text: 'Данные трека "' + this.vi.vc.gov + '" на время: ' + get_the_time(this.vi.dt) + ' не получены',
                                  color: 'warning',
                                  timeout: 30000
                                });
                            }
                        });
                        
                        if (!isEmpty(vi.route.id)){
                            MapLayersService.getRoutePoint(vi.route.id).then(data=>{
                                vi.route.points = data;
                                vi.route.stops = data.filter(it => it.name !== null);
                                ShowRouteMapService.init(Map, vi.route.stops, vi.route.points);
                                ShowRouteMapService.drawRoute(
                                        vi.route,
                                        vi.route.stops
                                );
                            });
                        }
                    });
                } catch(e){
                    console.log('ERR go-map:', e);
                }
            },   //getViolInfo
            popup(){
                map.panTo([this.vi.lon, this.vi.lat]);
                var s = '<h2 style="color:#d50000;margin-bottom:0.5rem;font-weight:300;">' + this.vi.name + '<h2>';
                if (this.vi.route.has){
                    s += '<h3>Маршрут № ' + this.vi.route.code + ', рейс ' + this.vi.trip.code + '</h3>';
                }
                if (this.vi.org.has){
                    s += '<div style="border-bottom:1px solid #e0e0e0;margin-bottom:0.5rem;">' + this.vi.org.name + '</div>';
                }
                if (!$utils.isEmpty(this.vi.she)){
                    s += '<b>Время по расписанию</b>: ' + this.vi.she;
                }
                s += '<br /><b>ТС</b>: ' + this.vi.vc.gov;
                s += '<br /><b>время события</b>: ' + get_the_time(this.vi.dt);
                s += '<br /><b>ш / д</b>: ' + Number(this.vi.lat).toFixed(4) + ' / ' + Number(this.vi.lon).toFixed(4);
                s += '<div id="map-geo-position"></div>';
                s += '<div style="margin-top:0.5rem;border-bottom:1px solid #e0e0e0;border-top:1px solid #e0e0e0;"><b>данные за период</b>: ' 
                        + get_the_time(this.vi.dt1, true) + ' - ' 
                        + get_the_time(this.vi.dt2, true) + '</div>';
                s += '<div style="text-align:right;margin-top:1rem;"><a id="map-close-popup" href="javascript:void(0);">закрыть</a></div>';
                if (!!popup){
                    popup.remove();
                }
                popup = new MapBoxGL.Popup({ maxWidth: '470px', closeButton: false, closeOnClick: false }).setLngLat([this.vi.lon, this.vi.lat]).setHTML(s).addTo(map);
                popup.on('close', function(e) {popup = null;});
                $(popup.getElement()).find('#map-close-popup').on('click', ()=>{
                    popup.remove();
                    popup = null;
                });
                $http.get('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + this.vi.lat + '&lon=' + this.vi.lon + '&zoom=18&addressdetails=1').then((addr) => {
                    console.log('addr', addr);
                    if (!!popup){
                        $(popup.getElement()).find('#map-geo-position').html(((addr.road) ? addr.road : addr.display_name));
                    }
                });
            }   //popup
        },
        watch: {
            violInfo(val){
                if (!!map){
                    Map.clearTracks();
                }
                if (!!val){
                    this.show = (new Date()).getTime();
                    this.getViolInfo();
                } else {
                    this.show = false;
                    this.vi = null;
                }
            }
        },
        render(h){
            const $m = this.$moment;
            const hasVi = !!this.vi;
            var childs = [];
            switch (this.state){
                case OM_STATE.none:
                    break;
                case OM_STATE.default:
                    break;
                case OM_STATE.loading: 
                    childs.push(h('v-skeleton-loader', {props: {type:'paragraph'}, class: "mt-5"}));
                    break;
                case OM_STATE.error: 
                    break;
            }   //switch (this.state
            
            childs.push(h('div', {
                attrs: {
                    id: "vi-map"
                },
                class: {"d-none": this.state !== OM_STATE.default, "vi-map-conte": true, "fill-height": true}
            }));
            
            return h('v-dialog', {
                props: {
                    value: this.show,
                    transition:"dialog-top-transition",
                    fullscreen: true,
                    eager: true,
                    contentClass: "vi-map-dialog"
                }
            }, [
                h('v-toolbar', {props: {dark: true, color: "primary", dense: true, width: '100%'}}, [
                    h('v-toolbar-title', [
                        hasVi ? h('div', this.vi.name) : null,
                        hasVi ? h('div', $moment(this.vi.dt).format('DD.MM.YYYY hh:mm')) : null,
                        hasVi ? h('div', this.vi.vc.gov) : null,
                        hasVi && (this.vi.route.has) ? h('div', this.vi.route.code + '.' + this.vi.route.name) : null,
                        hasVi && (this.vi.trip.has) ? h('div', '(' + this.vi.trip.code + ')') : null
                    ]),
                    h('v-spacer'),
                    h('v-toolbar-items', [
                        h('v-btn', {
                            props: {dark: true, text: true},
                            on: {click: ()=>{this.show = false;}}
                        }, 'закрыть')
                    ])
                ]),
                childs
            ]);
        }
    }
</script>
<style lang="scss" scoped>
    .vi-map-dialog {
        & .v-toolbar {
            &__title{
                display: flex;
                font-size: 0.9rem;
                & > *{
                    margin-right: 0.5rem;
                }
            }
        }
        & .vi-map-conte{
            padding: 0;
            position: relative;
            width: 100%;
            height: calc(100% - 48px);
        }
    }
</style>
